import React, { useState, useEffect } from "react";
import _ from "lodash";
import Loader from "react-loader-spinner";
import { PieChart } from "react-minimal-pie-chart";
import i18next from "i18next";

import { useGetPortefeuilles, useGetLignePortefeuille } from "../api/services";

import { IoEllipse } from "react-icons/io5";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./Portfolios.css";

import { PortfolioInfos, PortfolioDataTable, NoResults } from "../components";

const colors = [
  "#642E45",
  "#de9e36",
  "#074272",
  "#a24f0a",
  "#298689",
  "#002E45",
  "#000000",
  // "75%",
  "#926c7c",
  "#e8bb72",
  "#517b9c",
  "#bd8359",
  "#67a9ab",
  "#4c6c7c",
  "#7F7F7F",
  // "50%",
  "#b297a2",
  "#f4ddb9",
  "#a9bece",
  "#dec1ab",
  "#b2d4d5",
  "#a6b6be",
  "#A6A6A6",
  // "40%",
  "#d9cbd1",
  "#f9eedb",
  "#d4dee6",
  "#e1ebf4",
  "#efe0d5",
  "#d2dade",
  "#d9d9d9",
  // "30%",
  "#ece5e8",
  "#fcf7ed",
  "#e9eef2",
  "#f7f0ea",
  "#ebf4f4",
  "#e9edef",
  "#f4f4f4",
  // "#2268BF",
  // "#FF661B",
  // "#9B9B9B",
  // "#FFB400",
  // "#3093D2",
  // "#50A73A",
  // "#4D6582",
  // "#2CE4E8",
  // "#EB8767",
  // "#B51F10",
  // "#824068",
  // "#333221",
  // "#4F4D3E",
  // "#B88D2E",
  // "#36AF9F",
  // "#22D68C",
  // "#333221",
  // "#BFBDC1",
  // "#DEB841",
  // "#DE9E36",
  // "#E8DC2C",
  // "#96913C",
  // "#F4F4F4",
];

function Portfolios() {
  const [currentPortefeuilleId, setCurrentPortefeuilleId] = useState(null);
  const [currentPortefeuille, setCurrentPortefeuille] = useState(null);

  const [sortedLignesPorteFeuille, setSortedLignesPorteFeuille] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [dataColors, setDataColors] = useState({});
  const [ligneGrandTotal, setLigneGrandTotal] = useState(null);
  const { data: portefeuilles } = useGetPortefeuilles();
  const {
    data: lignesPortefeuille,
    status: lignesPortefeuilleStatus,
  } = useGetLignePortefeuille(currentPortefeuilleId);

  // Gestion porte feuille par défaut
  useEffect(() => {
    if (portefeuilles && portefeuilles.length > 0) {
      const p = _.find(portefeuilles, ["affichagedefaut", true]);

      setCurrentPortefeuilleId(
        p ? p.portefeuilleid : portefeuilles[0].portefeuilleid,
      );
      setCurrentPortefeuille(p || portefeuilles[0]);
    }
  }, [portefeuilles, setCurrentPortefeuilleId, setCurrentPortefeuille]);

  useGetLignePortefeuille(currentPortefeuilleId);

  useEffect(() => {
    if (lignesPortefeuille && lignesPortefeuille.length > 0) {
      const tempLignes = [...lignesPortefeuille];

      // Association couleur avec nomClasse
      const uniqueNomClasse = _.chain(tempLignes)
        .filter(function (ligne) {
          return ligne.typeligne !== "T" && ligne.typeligne !== "D";
        })
        .orderBy(["ordreaffichage", "valeur"], ["asc", "asc"])
        .map(function (ligne) {
          return ligne.nomclasse;
        })
        .uniq()
        .value();

      const tempColors = {};
      for (let i = 0; i < uniqueNomClasse.length; i++) {
        tempColors[uniqueNomClasse[i]] = colors[i];
      }
      setDataColors(tempColors);

      setLigneGrandTotal(_.find(tempLignes, { typeligne: "T" }));

      setSortedLignesPorteFeuille(
        _.chain(tempLignes)
          .filter(function (ligne) {
            return ligne.typeligne !== "T";
          })
          .orderBy(
            ["ordreaffichage", "typeligne", "valeur"],
            ["asc", "desc", "asc"],
          )
          .map(function (ligne) {
            return {
              ...ligne,
              visible: ligne.typeligne !== "D",
              detailVisible: false,
            };
          })
          .value(),
      );

      setChartData(
        _.chain(tempLignes)
          .filter(function (ligne) {
            return ligne.typeligne !== "T" && ligne.typeligne !== "D";
          })
          .orderBy(
            ["nomclasse", "typeligne", "ordreaffichage", "valeur"],
            ["asc", "desc", "asc", "asc"],
          )
          .map(function (ligne, index) {
            return {
              label: ligne.nomclasse,
              value: ligne.valorisationport,
              color: tempColors[ligne.nomclasse], // on utilise tempColors sinon le setDataColors n'a pas encore fait son effet
              style: { strokeWidth: parseInt(10 + index + 1) },
            };
          })
          .value(),
      );
    }
  }, [
    lignesPortefeuille,
    setLigneGrandTotal,
    setSortedLignesPorteFeuille,
    setChartData,
    setDataColors,
  ]);

  const toggleSubLevel = (nomClasse, valeur) => {
    setSortedLignesPorteFeuille(
      _.map(sortedLignesPorteFeuille, function (ligne) {
        // Si on a l'argument valeur c'est qu'on veut afficher le détail
        if (
          valeur &&
          ligne.valeur === valeur &&
          ligne.nomclasse === nomClasse
        ) {
          return { ...ligne, detailVisible: !ligne.detailVisible };
        }
        // Cas du niveau 2
        if (!valeur && ligne.nomclasse === nomClasse) {
          return {
            ...ligne,
            visible: !ligne.visible,
            detailVisible: ligne.visible ? false : ligne.detailVisible,
          };
        }
        // Cas du niveau 2
        if (!valeur && ligne.nomclasse === nomClasse && ligne.detailVisible) {
          return { ...ligne, detailVisible: !ligne.detailVisible };
        }

        return { ...ligne };
      }),
    );
  };

  if (lignesPortefeuilleStatus === "loading") {
    return (
      <Loader
        type="TailSpin"
        className="App align-center"
        color="#222222"
        height={80}
        width={80}
      />
    );
  }

  if (!portefeuilles || portefeuilles.length === 0) {
    return <NoResults>{i18next.t("Portefeuille_aucunPortefeuille")}</NoResults>;
  }

  return (
    <div className="portfolios">
      <div className="container">
        {/* Todo: filter component */}
        <div className="filter">
          <select
            name=""
            id=""
            value={
              currentPortefeuilleId === null ? "all" : currentPortefeuilleId
            }
            onChange={(e) => {
              if (e.target.value === "all") {
                return;
              }
              setSortedLignesPorteFeuille([]);
              setChartData([]);
              setDataColors({});
              setCurrentPortefeuille(
                _.find(portefeuilles, {
                  portefeuilleid: parseInt(e.target.value),
                }),
              );
              return setCurrentPortefeuilleId(e.target.value);
            }}
          >
            <option value="all">{i18next.t("Filter_portefeuille")}</option>
            {portefeuilles &&
              portefeuilles.length > 0 &&
              portefeuilles.map((portefeuille) => (
                <option
                  key={"portefeuille-" + portefeuille.portefeuilleid}
                  value={portefeuille.portefeuilleid}
                >
                  {portefeuille.libelle}
                </option>
              ))}
          </select>
        </div>

        {/* PortfolioInfos: infos portfolios component */}
        <PortfolioInfos infos={currentPortefeuille} />

        {/* Todo: graphic component */}
        <div className="distribution">
          <h2 className="todo">{i18next.t("Portefeuille_repartition")}</h2>

          {lignesPortefeuilleStatus === "success" &&
            lignesPortefeuille.length > 0 &&
            chartData &&
            dataColors && (
              <>
                {ligneGrandTotal ? (
                  <PieChart
                    className="distribution__chart"
                    data={chartData}
                    lineWidth={50}
                    paddingAngle={1}
                    reveal={100}
                    startAngle={320}
                    labelPosition={70}
                    labelStyle={(index) => ({
                      fill: "rgba(255, 255, 255, 0.8)",
                      fontSize: "8px",
                      fontWeight: "bold",
                      fontFamily: "SourceSansPro",
                    })}
                    label={({ dataEntry }) =>
                      Math.floor(
                        (dataEntry.value / ligneGrandTotal.valorisationport) *
                          100,
                      ) >= 10
                        ? Math.floor(
                            (dataEntry.value /
                              ligneGrandTotal.valorisationport) *
                              100,
                          ) + "%"
                        : ""
                    }
                  />
                ) : (
                  <PieChart
                    className="distribution__chart"
                    data={chartData}
                    lineWidth={25}
                    paddingAngle={2}
                    startAngle={320}
                    labelPosition={0}
                    labelStyle={{
                      fill: "rgba(0, 0, 0, 0.2)",
                      fontSize: "8px",
                      fontWeight: "bold",
                      fontFamily: "SourceSansPro",
                    }}
                    label={({ dataEntry }) => "TOTAL"}
                  />
                )}

                <ul className="distribution__legend">
                  {dataColors &&
                    _.map(Object.keys(dataColors), function (key) {
                      // Recherche valeur pour chaque classe
                      const data = _.find(chartData, { label: key });
                      return (
                        <li key={"legende-" + key}>
                          <IoEllipse
                            className="icon"
                            style={{ color: dataColors[key] }}
                          />
                          {key}
                          {ligneGrandTotal
                            ? " : " +
                              Math.floor(
                                (data.value /
                                  ligneGrandTotal.valorisationport) *
                                  100,
                              ) +
                              "%"
                            : ""}
                        </li>
                      );
                    })}
                </ul>
              </>
            )}
        </div>
        {/* Todo: manage error display */}
        {(lignesPortefeuilleStatus === "error" ||
          (lignesPortefeuilleStatus === "success" &&
            (!lignesPortefeuille || lignesPortefeuille.length === 0))) && (
          <div className="datas">
            <p>{i18next.t("Portefeuille_erreurSurvenue")}</p>
          </div>
        )}
        {/* Todo: datas component */}
        {lignesPortefeuilleStatus === "success" &&
          lignesPortefeuille.length > 0 && (
            <div className="datas">
              {/* <h2>{i18next.t("Portefeuille_presentationDonnees")}</h2> */}
              <table>
                <thead>
                  {/* Entête : Bandeau gris */}
                  {/* <tr className="values">
                    <th className="value">
                      {i18next.t("Portefeuille_valeurPourcentage")}
                    </th>
                    <th className="value-ptf">
                      {i18next.t("Portefeuille_valorPTF")}
                    </th>
                  </tr> */}
                  {ligneGrandTotal && (
                    <tr className="performance">
                      <th>
                        {i18next.t("Portefeuille_performance")}{" "}
                        {i18next.t("intlNumberWithOptions", {
                          val: ligneGrandTotal.perfytd,
                        })}{" "}
                        %
                      </th>
                      <th>
                        {i18next.t("intlNumberWithOptions", {
                          val: ligneGrandTotal.valorisationport,
                        }) +
                          " " +
                          (ligneGrandTotal.devisecotation
                            ? ligneGrandTotal.devisecotation
                            : " ")}
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {sortedLignesPorteFeuille &&
                    sortedLignesPorteFeuille.length > 0 &&
                    sortedLignesPorteFeuille.map((ligne, index) => (
                      <PortfolioDataTable
                        ligne={ligne}
                        color={dataColors[ligne.nomclasse]}
                        toggleSubLevel={toggleSubLevel}
                        key={"ptfdt-" + index}
                      />
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2">
                      <span className="legend">* cours estimé</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
      </div>
    </div>
  );
}

export default Portfolios;
