import React from "react";
import { useLocation } from "react-router-dom";
// import i18next from "i18next";

import { Disclaimer, PowerBy } from "./index";

import "./FooterApp.css";

export default function FooterApp() {
  const location = useLocation();

  return (
    <footer className="footerApp">
      {location.pathname !== "/login" &&
        !location.pathname.includes("/activate") && <Disclaimer />}
      <PowerBy />
    </footer>
  );
}
