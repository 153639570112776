import React, { createContext, useState } from "react";

export const UserContext = createContext();

export function UserProvider({ children, defaultUser }) {
  // Le user est stocké dans le state afin de pouvoir être modifiée à la volée
  const [user, setUser] = useState(defaultUser);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
