import React from "react";
import "./Filters.css";

import i18next from "i18next";

export default function FilterByDate({ years, selectedYear, setSelectedYear }) {
  const doSelectYear = (e) => {
    e.preventDefault();
    setSelectedYear(e.target.value === "all" ? null : e.target.value);
  };

  return (
    <div className="filter filter-date">
      <select
        name=""
        id=""
        value={selectedYear == null ? "all" : selectedYear}
        onChange={doSelectYear}
      >
        <option value="all">{i18next.t("Filter_date")}</option>

        {years &&
          years.length > 0 &&
          years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
      </select>
    </div>
  );
}
