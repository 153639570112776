import React from "react";
import { BiMenu } from "react-icons/bi";
import "./BtnMenu.css";

const BtnMenu = (props) => {
  return (
    <span onClick={props.openFunction} className="btn menu__btn">
      <BiMenu className="icon" /> <span className="menu__btn__txt">Menu</span>
    </span>
  );
};

export default BtnMenu;
