import React, { Component } from "react";
import i18next from "i18next";

export default class AppErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error: error };
  }

  componentDidCatch(error, info) {
    if (error) {
      console.log(error.stack);
    }
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className="App align-center">
          <p>{i18next.t("AppErrorBoundary_erreurSurvenue")}</p>
        </div>
      );
    }
    return this.props.children;
  }
}
