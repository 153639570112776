import React, { useState } from "react";

import { Logo, Footer } from "../components";

import { LoginForm } from "../components/LoginForm";
import { LoginVerifyCodeForm } from "../components/LoginVerifyCodeForm";

import "./Login.css";

function Login() {
  const [challengeContext, setChallengeContext] = useState();

  return (
    <>
      <header className="App-login">
        <Logo />

        {challengeContext ? (
          <LoginVerifyCodeForm challengeContext={challengeContext} />
        ) : (
          <LoginForm setChallengeContext={setChallengeContext} />
        )}
      </header>
      <Footer challengeContext={challengeContext} />
    </>
  );
}
export default Login;
