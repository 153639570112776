import React from "react";
import Avatar from "../images/avatar.png";

import "./Profil.css";

import i18next from "i18next";

function Profil({ endusersummary }) {
  if (!endusersummary) {
    return (
      <div className="card  card__profil">
        <h2>{i18next.t("Profil_nonConnecté")}</h2>
      </div>
    );
  }
  return (
    <div className="card card__profil">
      <img src={Avatar} alt="" />
      <h2>
        {endusersummary.prenom} {endusersummary.nom}
      </h2>
    </div>
  );
}

export default Profil;
