import { QueryClient } from "react-query";
import axios from "axios";
// import i18next from "i18next";

const BACKEND_URL = process.env.NODE_ENV === "development" ? "/api/" : "/api/";

const axiosInstance = axios.create({
  maxRedirects: 0,
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // 403 : retour à l'accueil pour login
    if (
      (error.response.status === 403 || error.response.status === 404) &&
      location.pathname !== "/login"
    ) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

const queryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: true,
        staleTime: 300000,
      },
    },
  });
};

const swallowErrorFetchGet = async function (url) {
  const p = {
    method: "GET",
    credentials: "include",
    url: BACKEND_URL + url,
  };

  const res = await axiosInstance(p);

  return await res.data;
};

const fetchGet = async function (url) {
  const p = {
    method: "GET",
    credentials: "include",
    url: BACKEND_URL + url,
  };

  const res = await axiosInstance(p);

  return await res.data;
};

const fetchGetBinary = async function (url) {
  const p = {
    method: "GET",
    credentials: "include",
    url: BACKEND_URL + url,
    responseType: "arraybuffer",
  };

  const res = await axiosInstance(p);

  return await res.data;
};

const fetchPost = async function (url, payload) {
  const p = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    mode: "cors",
    credentials: "include",
    data: JSON.stringify(payload),
    url: BACKEND_URL + url,
  };

  const res = await axiosInstance(p);

  return await res.data;
};

const fetchPostBinary = async function (url, payload) {
  const p = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "arraybuffer",
    mode: "cors",
    credentials: "include",
    data: JSON.stringify(payload),
    url: BACKEND_URL + url,
  };

  const res = await axiosInstance(p);

  return await res.data;
};

const fetchPostFormEncoded = async function (url, payload) {
  let formBody = [];
  for (const property in payload) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const p = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    mode: "cors",
    credentials: "include",
    data: formBody,
    url: BACKEND_URL + url,
  };

  const res = await axiosInstance(p);

  return res.data;
};

const fetchStub = async function (stub) {
  return await stub;
};

export {
  queryClient,
  fetchGet,
  swallowErrorFetchGet,
  fetchGetBinary,
  fetchPost,
  fetchPostBinary,
  fetchPostFormEncoded,
  fetchStub,
};
