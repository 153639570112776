import React from "react";
import moment from "moment";
import i18next from "i18next";
import "./PortfolioInfos.css";

export default function PortfolioInfos(props) {
  if (!props.infos) return <></>;

  return (
    <div className="infos">
      <div>
        <span className="label">{i18next.t("Portefeuille_date")}</span>
        <span className="value">
          {props.infos.dateextraction
            ? i18next.t("intlDateTime", {
                val: moment(props.infos.dateextraction).toDate(),
              })
            : "-"}
        </span>
      </div>
      <div>
        <span className="label">{i18next.t("Portefeuille_assureur")}</span>
        <span className="value">{props.infos.assureur || "-"}</span>
      </div>
      <div>
        <span className="label">{i18next.t("Portefeuille_depositaire")}</span>
        <span className="value">{props.infos.depositaire || "-"}</span>
      </div>
    </div>
  );
}
