import React from "react";

import "./Footer.css";

import i18next from "i18next";

function Footer({ challengeContext, currentPage }) {
  return (
    <footer>
      <div className="container">
        {challengeContext ? (
          <>
            <p className="confirm">
              {i18next.t("Footer_envoieSMSauTelephone")}
              <b>{challengeContext.phone}</b>.<br />
              {currentPage === "login" ||
                (currentPage === undefined && (
                  <>{i18next.t("Footer_entrerCodePourSeConnecter")}</>
                ))}
              {currentPage === "activate" && (
                <>{i18next.t("Footer_entrerCodepourActivation")}</>
              )}
            </p>
            <hr />

            <ul>
              <li>
                <p>
                  <b>{i18next.t("Footer_aideConnexionTitre")}</b>
                  {i18next.t("Footer_aideConnexionTexte")}
                  <a href="https://officium.com/contact/">
                    {i18next.t("Footer_aideConnexionLien")}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <b>{i18next.t("Footer_securiteTitre")}</b>
                  {i18next.t("Footer_securiteTexte")}
                </p>
              </li>
            </ul>
          </>
        ) : (
          <p className="signin">
            {i18next.t("Footer_pasActiveCompte")}
            <br />
            <a
              className="App-link"
              href="https://officium.com/contact/"
              target="_blank"
              rel="noreferrer"
            >
              {i18next.t("Footer_contactezNous")}
            </a>
          </p>
        )}
      </div>
    </footer>
  );
}

export default Footer;
