import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { useVerifyCode } from "../api/services";
import CustomSubmitErrors from "../components/organisms/CustomSubmitErrors";

import i18next from "i18next";

export function LoginVerifyCodeForm({ challengeContext }) {
  const verifyCodeMutation = useVerifyCode();
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.code) {
      errors.code = i18next.t("Login_champsRequis");
    }
    return errors;
  };

  const onSubmit = async (values) => {
    setDisabledSubmit(true);

    try {
      await verifyCodeMutation.mutateAsync({
        challenge: challengeContext.challenge,
        code: values.code.trim(),
      });
      location.href = "/dashboard";
    } catch (e) {
      return { [FORM_ERROR]: i18next.t("LoginCodeSMS_codeErrone") };
    } finally {
      setDisabledSubmit(false);
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <>
              <CustomSubmitErrors />
              <form onSubmit={handleSubmit} noValidate>
                <Field name="code">
                  {({ input, meta }) => (
                    <div
                      className={`field ${
                        (meta.error || meta.submitError) &&
                        meta.touched &&
                        "field--errors"
                      }`}
                    >
                      <input
                        {...input}
                        type="text"
                        placeholder={i18next.t(
                          "LoginCodeSMS_placeholderCodeSMS",
                        )}
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <div className="field--error">
                          {meta.error || meta.submitError}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
                <button type="submit" disabled={submitting || disabledSubmit}>
                  {i18next.t("LoginCodeSMS_buttonValider")}
                </button>
              </form>
              {/* Todo : display success after  */}
              {/* <Success /> */}
            </>
          );
        }}
      />
    </>
  );
}
