import React from "react";
import { Link } from "react-router-dom";

import { Menu, Logo /* , BtnLang */, BtnLogout } from "./index";

import "./Header.css";

function Header() {
  return (
    <header className="App-loggedin">
      <Menu />
      <Link to="/dashboard">
        <Logo />
      </Link>
      <div className="buttons">
        {/* <BtnLang /> |  */}
        <BtnLogout />
      </div>
    </header>
  );
}

export default Header;
