import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchGet,
  swallowErrorFetchGet,
  fetchGetBinary,
  fetchPost,
  fetchPostFormEncoded,
} from "./apiConfig";
// import moment from "moment";

/**
1ere etape du login: login et mot de passe
return:
{
    "challenge": "omjf10qa62yjsc02",
    "phone": "*0304"
}
*/
export const useLogin = () => {
  return useMutation(({ username, password }) => {
    const payload = {
      username: username,
      password: password,
    };
    return fetchPostFormEncoded("login", payload);
  });
};

/**
2eme étape du login: le challenge retourné ou useLogin + le code recu par SMS
(voir log du backend "must send code by SMS. Phone:(33) 601020304, verificationCode: ...")
return:
HTTP 403 => error
HTTP 302 => success
*/
export const useVerifyCode = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ challenge, code }) => {
      const payload = {
        username: challenge,
        mfacode: code,
      };
      return fetchPostFormEncoded("verifycode", payload);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    },
  );
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return fetchGet("logout");
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    },
  );
};

export const useGetConfig = () => {
  return useQuery(["config"], () => {
    return fetchGet("config");
  });
};

/** return:
{
    "nom": "Doe",
    "prenom": "John",
    "langue": "FR",
    "endusersummary": {
        "valorisation port": 61235.02,
        "devise": "EUR",
        "perfytd": 8.23,
        "datevalorisation": "2021-03-10T10:51:44.672009Z",
        "nbportefeuilles": 2,
        "nbrapportsdispo": 1,
        "datedernierrapport": null,
        "nbdocdispo": 0,
        "datedernierdoc": "2021-02-14T10:51:44.672009Z",
        "communication": "Welcome!"
    }
}
*/
export const useGetSummary = () => {
  const { data, status } = useQuery(
    "enduser/summary",
    () => {
      return swallowErrorFetchGet("enduser/summary");
    },
    {
      refetchOnWindowFocus: false, // on disable cette option car si on change de fenêtre entre le login et la vérification du code SMS on retourne sur la phase de login
      onSettled: (data, error) => {
        // console.log("swallow error");
      },
    },
  );

  return { data, status };
};

/** return:
[
    {
        "portefeuilleid": 1321423432,
        "libelle": "Portefeuille Consolidé",
        "affichagedefaut": true,
        "deviseptf": "EUR",
        "assureur": "Assur. Specs.",
        "depositaire": "Deposit. inc.",
        "dateextraction": "2021-03-08T10:51:44.672009Z"
    },
    {
        "portefeuilleid": 2156453,
        "libelle": "Portefeuille Gestion Dynamique",
        "affichagedefaut": false,
        "deviseptf": "EUR",
        "assureur": "Assur. Specs.",
        "depositaire": null,
        "dateextraction": "2021-03-09T10:51:44.672009Z"
    }
]
*/
export const useGetPortefeuilles = () => {
  const { data, status } = useQuery("enduser/portefeuille", () => {
    return fetchGet("enduser/portefeuille");
  });

  return { data, status };
};

/** return:
[
    {
        "portefeuilleid": 1321423432,
        "ordreaffichage": 46,
        "typeligne": "D",
        "nomclasse": "Trésorerie et liquidités",
        "valeur": "COMGEST GROWTH EUROPE R EUR",
        "valorisationport": 2810.3,
        "devisecotation": "EUR",
        "isin": "IE00B6X8T619",
        "quantite": null,
        "pru": null,
        "cours": 29.23,
        "datecours": null,
        "change": 1.6536,
        "pctport": 0.46,
        "perfytd": 12.99,
        "plusvalueport": 365.6
    },
    {
        "portefeuilleid": 1321423432,
        "ordreaffichage": 48,
        "typeligne": "D",
        "nomclasse": "Trésorerie et liquidités",
        "valeur": "COMGEST GROWTH WORLD R",
        "valorisationport": 1396.33,
        "devisecotation": "EUR",
        "isin": "IE00B6X8T619",
        "quantite": null,
        "pru": null,
        "cours": 28.9,
        "datecours": null,
        "change": 1.6536,
        "pctport": 0.23,
        "perfytd": 1.36,
        "plusvalueport": 1.0
    },
    ...
]
*/
export const useGetLignePortefeuille = (portefeuilleId) => {
  return useQuery(
    ["enduser/portefeuille", portefeuilleId, "lignes"],
    () => {
      return fetchGet("enduser/portefeuille/" + portefeuilleId + "/lignes");
    },
    {
      enabled: portefeuilleId != null,
    },
  );
};

/**
 * return
 * [
    {
        "documentid": 123456789,
        "descdoc": "Reporting Janvier 2021",
        "datedoc": "2021-01-05T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2021,
        "moisdoc": 1,
        "filename": "Report_2021_01.pdf",
        "numport": null,
        "descport": null,
        "luportail": false
    },
    {
        "documentid": 123456789,
        "descdoc": "Reporting Décembre 2020",
        "datedoc": "2020-12-07T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2020,
        "moisdoc": 12,
        "filename": "Report_2020_12.pdf",
        "numport": null,
        "descport": null,
        "luportail": true
    },
    {
        "documentid": 123456789,
        "descdoc": "Reporting Novembre 2020",
        "datedoc": "2020-11-05T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2020,
        "moisdoc": 11,
        "filename": "Report_2020_11.pdf",
        "numport": null,
        "descport": null,
        "luportail": true
    }
]
 */
export const useGetRapports = () => {
  const { data, status } = useQuery("enduser/document/rapport", () => {
    return fetchGet("enduser/document/rapport");
  });

  return { data, status };
};

/**
 * return
 * [
    {
        "documentid": 123456789,
        "descdoc": "Reporting Janvier 2021",
        "datedoc": "2021-01-05T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2021,
        "moisdoc": 1,
        "filename": "Report_2021_01.pdf",
        "numport": null,
        "descport": null,
        "luportail": false
    },
    {
        "documentid": 123456789,
        "descdoc": "Reporting Décembre 2020",
        "datedoc": "2020-12-07T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2020,
        "moisdoc": 12,
        "filename": "Report_2020_12.pdf",
        "numport": null,
        "descport": null,
        "luportail": true
    },
    {
        "documentid": 123456789,
        "descdoc": "Reporting Novembre 2020",
        "datedoc": "2020-11-05T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2020,
        "moisdoc": 11,
        "filename": "Report_2020_11.pdf",
        "numport": null,
        "descport": null,
        "luportail": true
    }
]
 */
export const useGetDocFonds = (docType) => {
  const { data, status } = useQuery("enduser/document/fond", () => {
    return fetchGet("enduser/document/fond");
  });

  return { data, status };
};

/**
 * return
 * [
    {
        "documentid": 123456789,
        "descdoc": "Reporting Janvier 2021",
        "datedoc": "2021-01-05T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2021,
        "moisdoc": 1,
        "filename": "Report_2021_01.pdf",
        "numport": null,
        "descport": null,
        "luportail": false
    },
    {
        "documentid": 123456789,
        "descdoc": "Reporting Décembre 2020",
        "datedoc": "2020-12-07T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2020,
        "moisdoc": 12,
        "filename": "Report_2020_12.pdf",
        "numport": null,
        "descport": null,
        "luportail": true
    },
    {
        "documentid": 123456789,
        "descdoc": "Reporting Novembre 2020",
        "datedoc": "2020-11-05T23:00:00Z",
        "typedoc": "RAPPORT",
        "anneedoc": 2020,
        "moisdoc": 11,
        "filename": "Report_2020_11.pdf",
        "numport": null,
        "descport": null,
        "luportail": true
    }
]
 */
export const useGetAutreDocuments = (docType) => {
  const { data, status } = useQuery("enduser/document/autredoc", () => {
    return fetchGet("enduser/document/autredoc");
  });

  return { data, status };
};

export const useUpdateDocumentLu = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ documentid, read }) => {
      const payload = {
        luportail: read,
      };
      return fetchPost(`enduser/document/${documentid}/update`, payload);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries("enduser/document/rapport");
        queryClient.invalidateQueries("enduser/document/autredoc");
        queryClient.invalidateQueries("enduser/document/fond");
        queryClient.invalidateQueries("enduser/summary");
      },
    },
  );
};

export const useDownloadInformationAttachedFile = () => {
  return useMutation((documentId) => {
    return fetchGetBinary("enduser/document/" + documentId + "/content");
  });
};

/* **** Activation de compte         ****************** */
/**
 * 1er etape de l'activation: on demande l'envoi d'un code d'activation
 * return: 
 * {
    "challenge": "46jcujz2s777juyq",
    "phone": "*0305"
  }
 */
export const useSendSmsActivation = (key) => {
  const { data, status } = useQuery(
    ["account/activation/sms", key],
    () => {
      return fetchPost("account/activation/sms", { key });
    },
    {
      enabled: key !== null,
      refetchOnWindowFocus: false, // on disable cette option car si on change de fenêtre entre le login et la vérification du code SMS on retourne sur la phase
    },
  );

  return { data, status };
};

/**
 * 2eme etape de l'activation: on verifie le code sms
 * return: true/false
 */
export const useVerifyCodeActivation = () => {
  return useMutation(({ challenge, code }) => {
    const payload = {
      challenge,
      code,
    };
    return fetchPost("account/activation/verify", payload);
  });
};

/**
 * 3eme etape de l'activation: on set le nouveau pwd
 * return: true/false
 */
export const useFinishActivation = () => {
  return useMutation(({ challenge, code }) => {
    const payload = { challenge, newPassword: code };
    return fetchPost("account/activation/finish", payload);
  });
};

/* **** Activation de compte         ****************** */

/**
 * Récupération du numéro de version de l'application
 */

export const useGetAppVersion = () => {
  return useQuery(["version"], () => {
    return fetchGet("ldf/version");
  });
};
