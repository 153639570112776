import React, { useState, Suspense } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { useLogin } from "../api/services";
import CustomSubmitErrors from "../components/organisms/CustomSubmitErrors";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import i18next from "i18next";

// Hooks
import { useShowPassword } from "../hooks";

export function LoginForm({ setChallengeContext }) {
  const showPasswordHook = useShowPassword();

  // const inputRef = useRef();

  // useEffect(() => {
  //   inputRef.current.focus();
  // }, [inputRef]);

  const loginMutation = useLogin();
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = i18next.t("Login_champsRequis");
    }
    if (!values.password) {
      errors.password = i18next.t("Login_champsRequis");
    }
    return errors;
  };

  const onSubmit = async (values) => {
    setDisabledSubmit(true);

    try {
      const res = await loginMutation.mutateAsync({
        username: values.username,
        password: values.password,
      });

      if (res && res.challenge) {
        setChallengeContext(res);
      }
    } catch (e) {
      return { [FORM_ERROR]: i18next.t("Login_identifiantsIncorrects") };
    } finally {
      setDisabledSubmit(false);
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Field name="username">
                {({ input, meta }) => (
                  <div
                    className={`field ${
                      (meta.error || meta.submitError) &&
                      meta.touched &&
                      "field--errors"
                    }`}
                  >
                    <input
                      {...input}
                      type="text"
                      placeholder={i18next.t("Login_nomUtilisateur")}
                    />
                    {(meta.error || meta.submitError) && meta.touched && (
                      <div className="field--error">
                        {meta.error || meta.submitError}
                      </div>
                    )}
                  </div>
                )}
              </Field>
              <Field name="password">
                {({ input, meta }) => (
                  <div
                    className={`field ${
                      (meta.error || meta.submitError) &&
                      meta.touched &&
                      "field--errors"
                    }`}
                  >
                    <div className="input-group suffix">
                      {showPasswordHook.isPasswordShown ? (
                        <MdVisibilityOff
                          className="ico input-group-addon"
                          onClick={showPasswordHook.togglePassword}
                        />
                      ) : (
                        <MdVisibility
                          className="ico input-group-addon"
                          onClick={showPasswordHook.togglePassword}
                        />
                      )}
                      <input
                        {...input}
                        type={
                          showPasswordHook.isPasswordShown ? "text" : "password"
                        }
                        id="password"
                        placeholder={i18next.t("Login_motDePasse")}
                      />
                    </div>

                    {(meta.error || meta.submitError) && meta.touched && (
                      <div className="field--error">
                        {meta.error || meta.submitError}
                      </div>
                    )}
                  </div>
                )}
              </Field>
              <CustomSubmitErrors />

              <button type="submit" disabled={submitting || disabledSubmit}>
                {i18next.t("Login_seConnecter")}
              </button>
            </form>
          );
        }}
      />
    </>
  );
}

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <LoginForm />
    </Suspense>
  );
}
