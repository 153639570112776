import i18next from "i18next";
import React from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import "./Filters.css";

export default function FilterHideRead({ hideRead, setHideRead }) {
  const toggleHideRead = (e) => {
    e.preventDefault();
    setHideRead(hideRead ? null : true);
  };

  return (
    <div className="filter filter-read">
      {hideRead && (
        <span className="filter__icon" onClick={toggleHideRead}>
          <MdVisibility className="icon" /> {i18next.t("Filter_unread_docs")}
        </span>
      )}
      {!hideRead && (
        <span className="filter__icon" onClick={toggleHideRead}>
          <MdVisibilityOff className="icon" /> {i18next.t("Filter_all_docs")}
        </span>
      )}
    </div>
  );
}
