import React from "react";
import { useFormState } from "react-final-form";
import _ from "lodash";

const CustomSubmitErrors = () => {
  const { dirtySinceLastSubmit, submitErrors } = useFormState();
  return (
    <>
      {!dirtySinceLastSubmit && (
        <div className="field">
          {submitErrors &&
            _.values(submitErrors).map((error, index) => (
              <div key={index} className="field--error">
                {error}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default CustomSubmitErrors;
