import React, { useState } from "react";
import { Form, Field } from "react-final-form";

import { useFinishActivation } from "../api/services";

import i18next from "i18next";

export function FinishActivationForm({ challengeContext }) {
  const finishActivationMutation = useFinishActivation();
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.username = i18next.t("LoginActivationCode_champsRequis");
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = i18next.t("LoginActivationCode_champsRequis");
    }
    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = i18next.t(
        "LoginActivationCode_confirmationMotDePasse",
      );
    }
    return errors;
  };

  const onSubmit = async (values) => {
    setDisabledSubmit(true);
    const res = await finishActivationMutation.mutateAsync({
      challenge: challengeContext.challenge,
      code: values.password,
    });
    if (res === true) {
      setDisabledSubmit(false);
      window.location = "/";
    } else {
      setDisabledSubmit(false);
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Field
                component="input"
                margin="dense"
                name="password"
                placeholder={i18next.t(
                  "LoginActivationCode_placeholderMotDePasse",
                )}
                type="password"
              />
              {/* inputRef={inputRef} */}
              <br />
              <Field
                component="input"
                margin="dense"
                name="confirmPassword"
                placeholder={i18next.t(
                  "LoginActivationCode_placeholderMotDePasseConfirmation",
                )}
                type="password"
              />
              <br />
              <button type="submit" disabled={submitting || disabledSubmit}>
                {i18next.t("LoginActivationCode_btnValider")}
              </button>
            </form>
          );
        }}
      />
    </>
  );
}
