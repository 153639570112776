import React from "react";
import { Link } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import "./Breadcrumbs.css";

const Breadcrumbs = ({ crumbs }) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className="breadcrumb">
      <ul>
        {/* Link back to any previous steps of the breadcrumb. */}
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <li key={key} className="breadcrumb__item--active">
              {name}
            </li>
          ) : (
            <li key={key}>
              {path === "/" && <IoHome className="icon" />}&nbsp;
              <Link to={path}> {name} </Link>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
