import React from "react";
import "./PortfolioDataTable.css";

import moment from "moment";
import i18next from "i18next";
import { IoChevronDownCircle, IoChevronUpCircle } from "react-icons/io5";

export default function PortfolioDataTable(props) {
  return (
    <>
      {props.ligne.typeligne === "S" && (
        <tr
          key={"l-" + props.ligne.nomclasse}
          className="level1"
          onClick={(e) => {
            return props.toggleSubLevel(props.ligne.nomclasse);
          }}
        >
          <td className="label">
            {props.ligne.visible ? (
              <IoChevronDownCircle
                className="icon"
                style={{ color: props.color }}
              />
            ) : (
              <IoChevronUpCircle
                className="icon"
                style={{ color: props.color }}
              />
            )}
            {props.ligne.nomclasse}
          </td>
          <td className="amount">
            {i18next.t("intlNumberWithOptions", {
              val: props.ligne.valorisationport,
              minimumFractionDigits: 2,
            })}{" "}
            {props.ligne.deviseaffichage}
          </td>
        </tr>
      )}
      {/* todo: Selected Porfolio Lines */}
      {props.ligne.typeligne === "D" && props.ligne.visible === true && (
        <tr
          key={
            "l-" +
            props.ligne.nomclasse +
            "-" +
            props.ligne.valeur +
            "-" +
            props.ligne.typeligne
          }
          className="level2"
          onClick={(e) => {
            return props.toggleSubLevel(
              props.ligne.nomclasse,
              props.ligne.valeur,
            );
          }}
        >
          <td className="label">{props.ligne.valeur}</td>
          <td className="amount">
            {props.ligne.valorisationport != null
              ? i18next.t("intlNumberWithOptions", {
                  val: props.ligne.valorisationport,
                  minimumFractionDigits: 2,
                }) +
                " " +
                props.ligne.deviseaffichage
              : ""}{" "}
          </td>
        </tr>
      )}
      {props.ligne.typeligne === "D" && props.ligne.detailVisible === true && (
        <tr
          key={
            "l-" +
            props.ligne.nomclasse +
            "-" +
            props.ligne.valeur +
            "-" +
            props.ligne.typeligne +
            "-detail"
          }
          className="level3"
        >
          <td colSpan="2">
            <div className="level3__infos">
              <div>
                <span className="label">
                  {i18next.t("Portefeuille_quantite")}
                </span>{" "}
                <span className="amount">
                  {i18next.t("intlNumberWithOptions", {
                    val: props.ligne.quantite,
                    minimumFractionDigits: 2,
                  })}
                </span>
                <br />
                {props.ligne.pru != null && (
                  <span>
                    <span className="label">
                      {i18next.t("Portefeuille_coursAchatMoyen")}
                    </span>

                    <span className="amount">
                      {i18next.t("intlNumberWithOptions", {
                        val: props.ligne.pru,
                        minimumFractionDigits: 2,
                      }) +
                        " " +
                        props.ligne.devisecotation}
                    </span>
                  </span>
                )}{" "}
              </div>
              <div>
                {props.ligne.cours != null && (
                  <span>
                    <span className="label">
                      {i18next.t("Portefeuille_coursCourant")}
                    </span>{" "}
                    <span className="amount">
                      {i18next.t("intlNumberWithOptions", {
                        val: props.ligne.cours,
                        minimumFractionDigits: 2,
                      }) +
                        " " +
                        props.ligne.devisecotation}
                    </span>
                    {props.ligne.coursestime && (
                      <span className="legend">*</span>
                    )}
                    <br />
                    <span className="label">
                      {i18next.t("Portefeuille_coursDate")}
                    </span>{" "}
                    <span className="amount">
                      {i18next.t("intlDateTime", {
                        val: moment(props.ligne.datecours).toDate(),
                      })}
                    </span>
                  </span>
                )}{" "}
              </div>

              <div>
                {props.ligne.plusvalueport != null && (
                  <span>
                    <span className="label">
                      {i18next.t("Portefeuille_valueLatente")}
                    </span>{" "}
                    <span className="amount">
                      {i18next.t("intlNumberWithOptions", {
                        val: props.ligne.plusvalueport,
                        minimumFractionDigits: 2,
                      }) +
                        " " +
                        props.ligne.deviseaffichage}
                    </span>
                  </span>
                )}{" "}
                <br />
                {props.ligne.perfytd != null && (
                  <span>
                    <span className="label">
                      {i18next.t("Portefeuille_performanceAnnee")}
                    </span>{" "}
                    <span className="amount">
                      {i18next.t("intlNumberWithOptions", {
                        val: props.ligne.perfytd,
                        minimumFractionDigits: 2,
                      }) + " %"}
                    </span>
                  </span>
                )}{" "}
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
