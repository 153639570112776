import React from "react";
import "./NoResults.css";

export default function NoResults(props) {
  return (
    <div className="noresults">
      <p>{props.children}</p>
    </div>
  );
}
