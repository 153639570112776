import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Logo, Footer } from "../components";

import { ActivationVerifyCodeForm } from "../components/ActivationVerifyCodeForm";
import { FinishActivationForm } from "../components/FinishActivationForm";
import { useSendSmsActivation } from "../api/services";

import "./Activate.css";

import i18next from "i18next";

function Activate() {
  const { key } = useParams();
  const [challengeContext, setChallengeContext] = useState();
  const [verificationDone, setVerificationDone] = useState(false);
  const {
    data: activateResult,
    status: statusSmsActivation,
  } = useSendSmsActivation(key);

  useEffect(() => {
    if (activateResult && activateResult.status !== 404) {
      setChallengeContext(activateResult);
    }
  }, [activateResult, setChallengeContext]);

  if (statusSmsActivation === "loading") {
    return (
      <Loader
        type="TailSpin"
        className="App align-center"
        color="#222222"
        height={80}
        width={80}
      />
    );
  }

  return (
    <>
      <header className="App-login">
        <Logo />
        {!challengeContext && <p>{i18next.t("Activate_lienActivation")}</p>}
        {challengeContext &&
        verificationDone !== true &&
        statusSmsActivation !== "404" ? (
          <ActivationVerifyCodeForm
            challengeContext={challengeContext}
            setVerificationDone={setVerificationDone}
          />
        ) : null}
        {verificationDone === true ? (
          <FinishActivationForm challengeContext={challengeContext} />
        ) : null}
      </header>
      <Footer challengeContext={challengeContext} currentPage={"activate"} />
    </>
  );
}
export default Activate;
