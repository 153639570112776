import React from "react";
import "./Filters.css";

import i18next from "i18next";

export default function FilterByType({ types, selectedType, setSelectedType }) {
  const doSelectType = (e) => {
    e.preventDefault();
    setSelectedType(e.target.value === "all" ? null : e.target.value);
  };
  return (
    <div className="filter filter-type ">
      <select
        name=""
        id=""
        value={selectedType == null ? "all" : selectedType}
        onChange={doSelectType}
      >
        <option value="all">{i18next.t("Filter_type")}</option>

        {types &&
          types.length > 0 &&
          types.map((t) => (
            <option key={t} value={t}>
              {t}
            </option>
          ))}
      </select>
    </div>
  );
}
