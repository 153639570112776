// Pages
import {
  Dashboard,
  Documents,
  Fonds,
  Portfolios,
  Reportings,
  Activate,
  Login,
} from "../pages";

const routes = [
  { path: "/", name: "Accueil", Component: Dashboard },
  { path: "/login", name: "Connexion", Component: Login },
  { path: "/dashboard", name: "Tableau de bord", Component: Dashboard },
  { path: "/portfolios", name: "Portefeuilles", Component: Portfolios },
  { path: "/fonds", name: "Fonds", Component: Fonds },
  { path: "/reportings", name: "Rapports", Component: Reportings },
  { path: "/documents", name: "Documents", Component: Documents },
  { path: "/activate/:key", name: "Activation de compte", Component: Activate },
];

export default routes;
