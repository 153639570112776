import { useState } from "react";

export default function useShowPassword() {
  // Initialize a boolean state
  const [isPasswordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!isPasswordShown);
  };

  return { isPasswordShown, togglePassword };
}
