import React from "react";

import "./Erreur404.css";

import i18next from "i18next";

const Erreur404 = () => {
  return (
    <div className="page-404">
      <div className="container">
        <h1>{i18next.t("Erreur404")}</h1>
      </div>
    </div>
  );
};

export default Erreur404;
