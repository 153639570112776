import React, { useState, useEffect } from "react";

import { useGetConfig } from "../api/services";

export default function Disclaimer() {
  const [disclaimer, setDisclaimer] = useState("");
  const { data } = useGetConfig();

  useEffect(() => {
    setDisclaimer(
      data && data.disclaimer_fr_portail ? data.disclaimer_fr_portail : "",
    );
  }, [data, setDisclaimer]);

  return <small className="disclaimer">{disclaimer}</small>;
}
