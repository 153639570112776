import React, { useContext } from "react";
import { UserContext } from "../global/providers/UserProvider";
import { useLogout } from "../api/services";
import i18next from "i18next";

import { AiOutlineLogout } from "react-icons/ai";

import "./BtnLogout.css";

export default function BtnLogout() {
  const logoutMutation = useLogout();
  const { user, setUser } = useContext(UserContext);

  const doLogout = (e) => {
    e.preventDefault();
    if (user) {
      logoutMutation.mutate();
      setUser(null);
    }
  };

  return (
    <div
      className="logout"
      onClick={doLogout}
      title={i18next.t("Login_seDeconnecter")}
    >
      <AiOutlineLogout className="icon" />
    </div>
  );
}
