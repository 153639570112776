import React from "react";

import i18next from "i18next";

class Logged extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
    };
  }

  render() {
    let wordDisplay;
    if (this.state.isLoggedIn === true) {
      wordDisplay = i18next.t("Login_seDeconnecter");
    } else {
      wordDisplay = i18next.t("Login_seConnecter");
    }
    return <h1>{wordDisplay}</h1>;
  }
}

export default Logged;
