import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "react-loader-spinner";
import i18next from "i18next";

import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { UserProvider } from "./global/providers/UserProvider";

import { queryClient } from "./api/apiConfig";
import AppErrorBoundary from "./components/AppErrorBoundary";

// Components
import { Breadcrumbs, Header, FooterApp } from "./components";
// Pages
import { Erreur404 } from "./pages";

import routes from "./global/routes";
import i18n, { i18nInit } from "./components/i18n";

import { useTranslation } from "react-i18next";

import "./App.css";

const appQueryClient = queryClient();

i18nInit();

function WrappedApp() {
  const { ready: i18nReady } = useTranslation("translation", {
    useSuspense: false,
  });

  // if (userStatus === "loading" || !i18nLoaded) {
  if (
    !i18nReady // pour etre sur qu'on a chargé les trads.
  ) {
    return (
      <Loader
        type="TailSpin"
        className="App align-center"
        color="#222222"
        height={80}
        width={80}
      />
    );
  }

  // Gestion du dynamique du nom des routes pour le fil d'ariane
  return (
    <div className="App">
      <Router>
        {window.location.pathname !== "/login" &&
          !window.location.pathname.includes("/activate") && <Header />}

        <Switch>
          {routes.map(({ path, name, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={(props) => {
                const crumbs = routes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                  .map(({ path, name, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                          (path, param) =>
                            path.replace(
                              `:${param}`,
                              props.match.params[param],
                            ),
                          path,
                        )
                      : path,
                    name: i18next.t(name), // On traduit le titre de la route pour le fil d'ariane le code de la clé de traduction doit commencer par le name de la route (global/routes.js)
                    ...rest,
                  }));

                // crumbs.map(({ name, path }) => console.log({ name, path }));

                return (
                  <div>
                    {window.location.pathname !== "/login" && (
                      <Breadcrumbs crumbs={crumbs} />
                    )}

                    <Component {...props} />
                  </div>
                );
              }}
            />
          ))}
          <Route component={() => <Erreur404 />} />
        </Switch>
        <FooterApp />
      </Router>
    </div>
  );
}

function App() {
  return (
    <AppErrorBoundary>
      <UserProvider>
        <QueryClientProvider client={appQueryClient}>
          <Suspense
            fallback={
              <Loader
                type="TailSpin"
                className="App align-center"
                color="#222222"
                height={80}
                width={80}
              />
            }
          >
            <WrappedApp lang={i18n.language} />
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </Suspense>
        </QueryClientProvider>
      </UserProvider>
    </AppErrorBoundary>
  );
}

export default App;
