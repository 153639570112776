import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { useVerifyCodeActivation } from "../api/services";
import CustomSubmitErrors from "../components/organisms/CustomSubmitErrors";

import i18next from "i18next";

export function ActivationVerifyCodeForm({
  challengeContext,
  setVerificationDone,
}) {
  const verifyCodeMutation = useVerifyCodeActivation();
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.code) {
      errors.code = i18next.t("LoginActivationCode_champsRequis");
    }
    return errors;
  };

  const onSubmit = async (values) => {
    setDisabledSubmit(true);
    const response = await verifyCodeMutation.mutateAsync({
      challenge: challengeContext.challenge,
      code: values.code.trim(),
    });
    if (response === true) setVerificationDone(true);
    else {
      setDisabledSubmit(false);
      return { [FORM_ERROR]: i18next.t("LoginActivationCode_invalide") };
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <>
              <CustomSubmitErrors />
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  component="input"
                  name="code"
                  placeholder={i18next.t("LoginActivationCode_codeSMS")}
                  type="text"
                />

                <br />
                <button type="submit" disabled={submitting || disabledSubmit}>
                  {i18next.t("LoginActivationCode_activationCompte")}
                </button>
              </form>
            </>
          );
        }}
      />
    </>
  );
}
