import React, { useState, useEffect } from "react";

import {
  Document,
  FilterByDate,
  FilterByType,
  FilterHideRead,
  NoResults,
} from "../components";

import _ from "lodash";
import { useGetRapports } from "../api/services";

import "./Reportings.css";

import i18next from "i18next";

function Reportings() {
  const { data: documents } = useGetRapports();
  const [years, setYears] = useState([]);
  const [types, setTypes] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedType, setSelectedType] = useState();
  const [hideRead, setHideRead] = useState();

  useEffect(() => {
    setYears(_.orderBy(_.uniq(_.map(documents, "anneedoc"), _.identity)));
  }, [documents, setYears]);

  useEffect(() => {
    setTypes(_.orderBy(_.uniq(_.map(documents, "typedoc"), _.identity)));
  }, [documents, setTypes]);

  useEffect(() => {
    if (selectedYear || selectedType || hideRead) {
      setFilteredDocuments(
        _.orderBy(
          _.filter(documents, function (d) {
            return (
              (selectedYear == null || "" + d.anneedoc === selectedYear) &&
              (selectedType == null || "" + d.typedoc === selectedType) &&
              (!hideRead || !d.luportail)
            );
          }),
          ["anneedoc", "moisdoc"],
          ["desc", "desc"],
        ),
      );
    } else {
      setFilteredDocuments(
        _.orderBy(documents, ["anneedoc", "moisdoc"], ["desc", "desc"]),
      );
    }
  }, [documents, selectedYear, selectedType, hideRead, setFilteredDocuments]);

  if (!documents || documents.length === 0) {
    return <NoResults>{i18next.t("Rapports_aucunRapports")}</NoResults>;
  }

  return (
    <div className="page-reportings">
      <div className="container">
        <FilterByDate
          years={years}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />

        <FilterByType
          types={types}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
        <FilterHideRead hideRead={hideRead} setHideRead={setHideRead} />

        <div className="card reportsList">
          <h2>{i18next.t("Rapports")}</h2>

          {filteredDocuments &&
            filteredDocuments.length > 0 &&
            filteredDocuments.map((doc, key) => (
              <Document key={key} doc={doc} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Reportings;
