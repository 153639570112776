import React from "react";
import logo from "../images/officium-logo.png";

import "./Logo.css";

function Logo() {
  const client = "Officium";
  return (
    <>
      <img
        src={logo}
        className="App-logo"
        alt={"Logo " + client}
        width="300px"
      />
    </>
  );
}

export default Logo;
