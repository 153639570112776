import React, { useEffect, useState } from "react";
import moment from "moment";
import i18next from "i18next";
import { IoDownload } from "react-icons/io5";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import "./Document.css";
import {
  useUpdateDocumentLu,
  useDownloadInformationAttachedFile,
} from "../api/services";

export default function Document({ doc }) {
  const mutation = useUpdateDocumentLu();
  const mutationDownload = useDownloadInformationAttachedFile();
  const [fileData, setFileData] = useState(null);

  const toggleRead = (e) => {
    e.preventDefault();
    mutation.mutate({ documentid: doc.documentid, read: !doc.luportail });
  };

  const handleRead = () => {
    mutation.mutate({ documentid: doc.documentid, read: true });
  };

  useEffect(() => {
    if (fileData) {
      const blob = new Blob([fileData], { type: "application/pdf" });
      const objectUrl = URL.createObjectURL(blob);
      // window.open(objectUrl);
      const downloadLink = document.createElement("a");
      downloadLink.href = objectUrl;
      downloadLink.target = "_blank";
      // downloadLink.download = exportedFilename;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [fileData]);

  const doDownloadAttachedFile = async (documentId) => {
    setFileData(await mutationDownload.mutateAsync(documentId));
    handleRead();
  };
  return (
    <div className="document">
      <div className="document__icon">
        <IoDownload
          className="icon"
          onClick={() => doDownloadAttachedFile(doc.documentid)}
        />
      </div>
      <div className="document__info">
        <h3>
          <a onClick={() => doDownloadAttachedFile(doc.documentid)}>
            {doc.descdoc}
          </a>
        </h3>
        <span className="document__date">
          {i18next.t("Documents_miseADisposition")}
          {" " +
            i18next.t("intlDateTime", {
              val: moment(doc.datedoc).toDate(),
            })}
        </span>
      </div>
      <div className="document__icon" onClick={toggleRead}>
        {doc.luportail && (
          <MdVisibility className="icon" title={i18next.t("Documents_lu")} />
        )}
        {!doc.luportail && (
          <MdVisibilityOff
            className="icon"
            title={i18next.t("Documents_nonlu")}
          />
        )}
      </div>
    </div>
  );
}
