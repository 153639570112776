import React from "react";
import i18next from "i18next";

import { useGetAppVersion } from "../api/services";

export default function PowerBy() {
  const { data: versionData } = useGetAppVersion();
  return (
    <small className="powerby">
      v{versionData} {i18next.t("Footer_poweredBy")}{" "}
      <i>{i18next.t("Footer_poweredBySam")}</i>
    </small>
  );
}
