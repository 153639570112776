import React, { useEffect, useContext } from "react";
import i18n from "../components/i18n";

import { Card, Communication, Profil } from "../components";

import { MdAccountBalance } from "react-icons/md";
import { IoDocument, IoDocumentAttach, IoDocumentText } from "react-icons/io5";

import { useGetSummary } from "../api/services";
import { UserContext } from "../global/providers/UserProvider";

import "./Dashboard.css";

import i18next from "i18next";

function Dashboard() {
  const { data, status: userStatus } = useGetSummary();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    if (data) {
      setUser(data);
      if (data.langue && !i18n.language) {
        i18n.changeLanguage(data.langue.toLowerCase());
      }
    }
  }, [data, userStatus, setUser]);

  if (userStatus !== "loading") {
    return (
      <div className="page-dashboard">
        <Profil endusersummary={data} />
        <Communication endusersummary={data.endusersummary} />
        <Card
          link="/portfolios"
          // number={data.endusersummary.nbportefeuilles}
          text={i18next.t("Portefeuilles")}
          styleName="card__portfolios"
        >
          <MdAccountBalance className="icon" />
        </Card>
        <Card
          link="/reportings"
          number={data.endusersummary.nbrapportsdispo}
          text={i18next.t("Rapports")}
          styleName="card__reportings"
        >
          <IoDocumentText className="icon" />
        </Card>
        <Card
          link="/documents"
          number={data.endusersummary.nbdocdispo}
          text={i18next.t("Documents")}
          styleName="card__documents"
        >
          <IoDocumentAttach className="icon" />
        </Card>
        <Card
          link="/fonds"
          number={data.endusersummary.nbdocfonddispo}
          text={i18next.t("Fonds")}
          styleName="card__equity"
        >
          <IoDocument className="icon" />
        </Card>
      </div>
    );
  } else return <></>;
}

export default Dashboard;
