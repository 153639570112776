import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";

function Card(props) {
  return (
    <div className={`card ${props.styleName}`}>
      <Link to={props.link}>
        <span className="card__icon">{props.children}</span>
        <div>
          {props.number !== undefined && props.number !== 0 && (
            <span className="number">{props.number}</span>
          )}
          <h2>{props.text}</h2>
        </div>
      </Link>
    </div>
  );
}

export default Card;
