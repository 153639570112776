import React from "react";

import "./Card.css";
import "./Communication.css";

import i18next from "i18next";

function Communication({ endusersummary }) {
  return (
    <div
      className={`card card__communication ${
        !endusersummary.communication ? "card--empty" : ""
      }`}
    >
      {/* <h2>{i18next.t("Communication")}</h2> */}
      <p>
        {endusersummary.communication
          ? endusersummary.communication
          : i18next.t("Communication_aucuneCommunication")}
      </p>
    </div>
  );
}

export default Communication;
