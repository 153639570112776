import React, { useState } from "react";
import { Link } from "react-router-dom";

import { MdAccountBalance } from "react-icons/md";
import i18next from "i18next";
import {
  IoHome,
  IoDocument,
  IoDocumentAttach,
  IoDocumentText,
} from "react-icons/io5";
// TODO: suggestion : change MdAccountBalance by this one > IoWallet,
import { BtnMenu } from "./index";

import "./Menu.css";

function Menu(props) {
  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleMenuFnc = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  const closeMenu = (e) => {
    if (e.target.className === "menu__btn__txt") return;
    setToggleMenu(false);
  };

  return (
    <>
      <BtnMenu openFunction={toggleMenuFnc} />
      {toggleMenu && (
        <div className="overlay" onClick={closeMenu}>
          <div className="menu" onClick={toggleMenuFnc}>
            {/* Menu List */}
            <ul>
              <li>
                <Link to="/dashboard">
                  <IoHome className="icon" />
                  {i18next.t("TableauDeBord")}
                </Link>
              </li>
              <li>
                <Link to="/portfolios">
                  <MdAccountBalance className="icon" />
                  {i18next.t("Portefeuilles")}
                </Link>
              </li>
              <li>
                <Link to="/reportings">
                  <IoDocumentText className="icon" />
                  {i18next.t("Rapports")}
                </Link>
              </li>
              <li>
                <Link to="/fonds">
                  <IoDocument className="icon" />
                  {i18next.t("Fonds")}
                </Link>
              </li>
              <li>
                <Link to="/documents">
                  <IoDocumentAttach className="icon" />
                  {i18next.t("Documents")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Menu;
