import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import moment from "moment";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

export const i18nInit = (initCompleted) => {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
      {
        detection: DETECTION_OPTIONS,
        supportedLngs: ["en", "fr"],
        fallbackLng: "fr",
        debug: false,
        backend: {
          // for all available options read the backend's repository readme file
          loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
          /* format: function (value, format, lng) {
            if (format === "numberFormatFR" || format === "numberFormatEN") {
              if (typeof value !== "number") {
                return value;
              }

              const cents = Math.round(value * 100);
              let intPart = Math.trunc(cents / 100); // Math.trunc et pas Math.round
              intPart = intPart.toLocaleString(lng); // Ajoute un " " tous les milliers en FR, ajoute un "," tous les miliers en EN
              let floatPart = String(Math.abs(cents));
              floatPart =
                floatPart !== "0"
                  ? floatPart.substring(floatPart, floatPart.length - 2)
                  : "00"; // 00 pour le cas où cents === 0

              if (format === "numberFormatEN") {
                return intPart + "." + floatPart;
              } else {
                // format === 'numberFormatFR'
                return intPart + "," + floatPart;
              }
            }
            if (value instanceof Date) {
              return moment(value).format(format);
            }

            return value;
          }, */
        },
      },
      (err, t) => {
        if (initCompleted) {
          initCompleted(err, t);
        }
      },
    );
};

export default i18n;
